import React, { useCallback, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { Container } from './styles';

import qrcode from '~/assets/defaults/qr-code.png';
import api from '~/services/api';
import InfiniteScroll from '~/components/InfiniteScroll';

interface ITicket {
  id: number;
  event: string;
  date: string;
  address: string;
  category: string;
  status: string;
  quantity: number;
  slug: string;
}

interface ITicketResponse {
  data: ITicket[];
  from: number;
  to: number;
  total: number;
  pages: number;
}

const Canceled: React.FC = () => {
  const [tickets, setTickets] = useState<ITicket[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);

  const handleLoadTickets = useCallback(async (pageData) => {
    try {
      const response = await api.get<ITicketResponse>('tickets/users', {
        params: {
          page: pageData,
          status: 'canceled',
        },
      });

      if (pageData === 1) {
        setTickets(response.data.data);
      } else {
        setTickets((state) => [...state, ...response.data.data]);
      }
      setTotalPages(response.data.pages);
    } catch (error) {
      // console.log(error);
    } finally {
      setLoadingMore(false);
    }
  }, []);

  useEffect(() => {
    handleLoadTickets(1);
  }, [handleLoadTickets]);

  const handleLoad = useCallback(async () => {
    if (!loadingMore) {
      setLoadingMore(true);
      if (page < totalPages) {
        await handleLoadTickets(page + 1);
        setPage(page + 1);
      } else {
        setLoadingMore(false);
      }
    }
  }, [handleLoadTickets, loadingMore, page, totalPages]);

  return (
    <Container>
      <InfiniteScroll onInfiniteLoad={handleLoad} className="infinite-scroll">
        {tickets.map((ticket) => (
          <div key={ticket.id} className="padding-bs">
            <Link
              to={`${process.env.PUBLIC_URL}/meus-ingressos/${ticket.slug}`}
            >
              <div className="w-100 bg-ticket d-flex p-3 front mt-3">
                <div className="me-auto pe-4 pe-sm-5 align-self-center">
                  <h2 className="title-h2 h6 fw-bold text-start">
                    {ticket.event}
                  </h2>
                  <div className="d-flex">
                    <div className="left-side text-start">
                      <span className="d-block mb-2">Data:</span>
                      <span className="d-block mb-2">Local:</span>
                      <span className="d-block mb-2">Categoria:</span>
                    </div>
                    <div className="right-side text-start ps-2 ps-sm-4">
                      <span className="d-block mb-2">
                        {format(parseISO(ticket.date), 'dd/MM/yyyy')}
                      </span>
                      <span className="d-block mb-2">
                        {ticket.address.replace('N˚0', 'S/N')}
                      </span>
                      <span className="d-block mb-2">
                        {ticket.category || '-'}
                      </span>
                    </div>
                  </div>
                </div>

                <div>
                  <img src={qrcode} alt="qrcode" />
                  <p className="px-2 py-1 mb-0 mt-2">
                    Identificador <br />#{ticket.id + 1000000000}
                  </p>
                </div>
                <div className="position-relative circles">
                  <div className="circle-top" />
                  <div className="small-circle" />
                  <div className="small-circle" />
                  <div className="small-circle" />
                  <div className="small-circle" />
                  <div className="small-circle" />
                  <div className="small-circle" />
                  <div className="small-circle" />
                  <div className="small-circle" />
                  <div className="small-circle" />
                  <div
                    className={`circle-bottom btn-circle-bottom ${
                      ticket.quantity > 1 ? 'white-bg' : ''
                    }`}
                  />
                </div>
              </div>
              {Array.from({
                length: ticket.quantity - 1 > 2 ? 2 : ticket.quantity - 1,
              }).map((_, index) => (
                <div className={`bg-ticket d-flex p-3 behind${index + 1}`}>
                  <div className="me-auto pe-4 pe-sm-5">
                    <h2 className="h6 fw-bold mb-3">Evento</h2>
                    <div className="d-flex">
                      <div className="left-side">
                        <span className="d-block mb-2">Data:</span>
                        <span className="d-block mb-2">Local:</span>
                        <span className="d-block mb-2">Categoria:</span>
                        <span className="d-block">Tipo:</span>
                      </div>
                      <div className="right-side ps-2 ps-sm-4">
                        <span className="d-block mb-2">01/08/2023</span>
                        <span className="d-block mb-2">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit
                        </span>
                        <span className="d-block mb-2">Corrida</span>
                        <span className="d-block">Saída 01</span>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <img src={qrcode} alt="qrcode" />
                    <p className="px-2 py-1 mb-0 mt-2">
                      Identificador <br />#{ticket.id + 1000000000}
                    </p>
                  </div>
                  <div className="position-relative circles">
                    <div className="circle-top" />
                    <div className="small-circle" />
                    <div className="small-circle" />
                    <div className="small-circle" />
                    <div className="small-circle" />
                    <div className="small-circle" />
                    <div className="small-circle" />
                    <div className="small-circle" />
                    <div className="small-circle" />
                    <div className="small-circle" />
                    <div
                      className={`circle-bottom ${
                        index === 0 && ticket.quantity - 1 > 1 ? 'white-bg' : ''
                      }`}
                    />
                  </div>
                </div>
              ))}
            </Link>
          </div>
        ))}
      </InfiniteScroll>
    </Container>
  );
};

export default Canceled;
