import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useGoogleLogin } from '@react-oauth/google';

import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';

import { Container, Bar } from './styles';
import Input from '~/components/Input';
import ButtonBack from '~/components/ButtonBack';
import VerifyAccount from './VerifyAccount';
import { KrGoogle } from '~/components/KoroIcons';
import { useAuth } from '~/hooks/Auth';

interface IFormData {
  name: string;
  email: string;
  password: string;
  confirm_password: string;
}

interface IUser {
  id: string;
  name: string;
  email: string;
  password: string;
}

export interface ILocation {
  userData: {
    email: string;
    given_name: string;
    family_name: string;
  };
}

const Signup: React.FC = () => {
  const { signIn, signInGoogle } = useAuth();
  const location = useLocation<ILocation>();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [user, setUser] = useState({} as IUser);
  const [securityPercent, setSecurityPercent] = useState(0);
  const [verify, setVerify] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    const credentials = localStorage.getItem('@Koro:credentials');
    if (credentials) {
      setUser(JSON.parse(credentials));
    }
  }, []);

  useEffect(() => {
    if (location.state?.userData) {
      setName(
        `${location.state.userData.given_name} ${location.state.userData.family_name}`
      );
      setEmail(location.state.userData.email);
    }
  }, [location.state]);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string().required('O e-mail é obrigatório'),
          name: Yup.string().required('O nome é obrigatório'),
          password: Yup.string().required('A senha é obrigatório'),
          confirm_password: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'As senhas devem ser iguais'
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          profile_id: 3,
          type_id: 2,
          name: data.name,
          email: data.email,
          password: data.password,
        };

        const response = await api.post('users/sessions/create', formData);

        await api.post(
          'additional-informations',
          {
            user_id: response.data.id,
            verified: location.state?.userData?.email === data.email,
          },
          {
            params: {
              isGoogle: location.state?.userData?.email === data.email,
            },
          }
        );

        setUser({
          id: response.data.id,
          name: data.name,
          email: data.email,
          password: data.password,
        });

        if (location.state?.userData?.email === data.email) {
          setVerify(false);
        }

        localStorage.setItem(
          '@Koro:credentials',
          JSON.stringify({
            id: response.data.id,
            name: data.name,
            email: data.email,
            password: data.password,
          })
        );

        history.push(
          `${process.env.PUBLIC_URL}/cadastro/verificar-conta`,
          location.state
            ? {
                userData: location.state.userData,
              }
            : undefined
        );
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      }
    },
    [history, location.state]
  );

  const handleClickStart = useCallback(() => {
    signIn({
      email: user.email,
      password: user.password,
    });
  }, [signIn, user.email, user.password]);

  const handleChangePassword = useCallback((e) => {
    const { value } = e.target;

    let security = 0;
    security += value.length >= 8 ? 1 : 0;
    security += /[A-Z]/.test(value) ? 1 : 0;
    security += /[a-z]/.test(value) ? 1 : 0;
    security += /[0-9]/.test(value) ? 1 : 0;
    security += /[!@#$%^&*(),.?":{}|<>]/.test(value) ? 1 : 0;

    setSecurityPercent((security * 100) / 5);
  }, []);

  const handleSuccess = useCallback(
    (data) => {
      signInGoogle(data);
    },
    [signInGoogle]
  );

  const handleFailure = useCallback(() => {
    Swal.fire(
      'Oops...',
      'Ocorreu um erro ao realizar o login com google tente novamente ou contate o administrador!'
    );
  }, []);

  const googleLogin = useGoogleLogin({
    onSuccess: handleSuccess,
    onError: handleFailure,
  });

  return (
    <Container>
      {location.pathname === '/cadastro' && (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-11 text-center pt-3">
              <ButtonBack className="mt-3" />
              <div className="banner my-3" />
              <h1 className="text-start mb-3">
                Faça seu cadastro{' '}
                <span className="fw-bold">gratuitamente!</span>
              </h1>
            </div>

            <div className="col-11 px-3">
              <Form
                ref={formRef}
                onSubmit={handleSubmit}
                className="d-flex flex-column pb-3"
              >
                <label className="mb-3">
                  <span className="fw-normal d-block mb-2">Email</span>
                  <Input
                    name="email"
                    type="email"
                    className="bd-input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </label>
                <label className="mb-3">
                  <span className="fw-normal d-block mb-2">Nome</span>
                  <Input
                    name="name"
                    className="bd-input"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </label>
                <label className="mb-3">
                  <span className="fw-normal d-block mb-2">Senha</span>
                  <Input
                    type="password"
                    name="password"
                    className="bd-input"
                    onChange={handleChangePassword}
                  />
                  {!!securityPercent && <Bar percent={securityPercent} />}
                  {securityPercent > 0 && securityPercent < 100 && (
                    <span className="small text-danger error">
                      É recomendado que a senha tenha ao menos 8 caracteres,
                      incluindo letras maiúsculas e minúsculas, números e
                      símbolos.
                    </span>
                  )}
                </label>

                <label className="mb-3">
                  <span className="fw-normal d-block mb-2">
                    Confirmar Senha
                  </span>
                  <Input
                    type="password"
                    name="confirm_password"
                    className="bd-input"
                  />
                </label>

                <button
                  type="submit"
                  className="btn btn-login h6 fw-normal py-2 mt-4"
                >
                  Cadastrar
                </button>
                {!location.state?.userData && (
                  <>
                    <span className="text-center fw-normal d-block or">ou</span>
                    <button
                      type="button"
                      className="d-flex align-items-center justify-content-center btn btn-login h6 fw-normal py-2 mt-2"
                      onClick={() => googleLogin()}
                    >
                      <KrGoogle
                        size={20}
                        color="#fff"
                        className="g-icon text-white me-2"
                      />{' '}
                      Cadastrar com o Google
                    </button>
                  </>
                )}
              </Form>
              <p className="text-center mb-1 small mt-3">
                <span className="">Já tem uma conta? Faça login</span>{' '}
                <Link to={`${process.env.PUBLIC_URL}/login`} className="">
                  aqui.
                </Link>
              </p>
            </div>
          </div>
        </div>
      )}
      {location.pathname === '/cadastro/verificar-conta' && (
        <VerifyAccount onClickStart={handleClickStart} verify={verify} />
      )}
    </Container>
  );
};

export default Signup;
